'use strict';

var angular = require('angular');

var QAService = /*@ngInject*/ function(
  $q,
  $http,
  REST_URL,
  APP_DATA,
  LIBRARY_VIEW_CODES,
  ASSET_STATUS,
  USER_DATA,
  $state,
  JobService,
  EventLoggerService
) {
  var _self = this;

  _self.data = {};
  _self.data.reviewStarted = false;
  _self.data.allAssetsReviewed = false;
  _self.data.hasRejection = false;

  _self.getRejectionReasons = function() {
    EventLoggerService.track('QA:GET REJECTION_REASONS', {
      metaJobId: _self.job ? _self.job.id : undefined
    }, true);
    return $http.get(`${REST_URL.base}/data/rejectionReason`);
  }

  _self.validateAllAssetsReviewed = function() {
    _self.data.allAssetsReviewed = _self.allReviewSelected();
    _self.data.hasRejection = _self.findAssetRejection();
  };

  _self.getLibraryViewCodePredictionsForMetaJob = function(metaJobId) {
    EventLoggerService.track('QA:GET PREDICTIONS', {
      metaJobId: metaJobId
    }, true);
    return $http.get(`${REST_URL.base}/libraryViewCodes/predict/${metaJobId}`);
  };

  _self.getValidLibraryViewCodes = function() {
    return LIBRARY_VIEW_CODES;
  };

  _self.get = function(id) {
    if (id && _self.assets) {
      return _self.assets[id];
    }
    return _self.assets;
  };

  _self.set = function(id, flag) {
    _self.data.reviewStarted = true;
    if (flag !== undefined) {
      _self.assets[id].approved = flag;
    }

    if (flag === true) {
      _self.removeAllAnnotations(id);
    }
    // Check if review is complete
    _self.validateAllAssetsReviewed();
  };

  _self.sendAnnotationToNewRelic = function(eventType, metaJobId, assetId, cropType, annotation) {
    var annotationJson = annotation.toJSON ? JSON.stringify(annotation.toJSON) : JSON.stringify(annotation);
    var partSize = 4096;
    var parts = annotationJson.length / partSize;
    for (var i = 0; i <= parts; i++) {
      EventLoggerService.track(eventType, {
        metaJobId: _self.job.id,
        assetId: assetId,
        cropType: cropType,
        annotationPart: i,
        annotationJson: annotationJson.slice(i * partSize, (i + 1) * partSize),
      }, true);
    }
  };

  _self.setAnnotation = function(id, cropType, json) {
    if (
      _self.assets[id].cropTypes[cropType] &&
      json &&
      (json.img !== '' || json.notes.length || json.toJSON.objects.length)
    ) {
      json.createdBy = USER_DATA;
      json.createdOn = new Date().toJSON();

      _self.sendAnnotationToNewRelic('QA:SET ANNOTATION', _self.job.id, id, cropType, json);
      _self.assets[id].cropTypes[cropType].annotation = json;
    }
  };

  _self.clearAnnotation = function(id, cropType) {
    EventLoggerService.track('QA:CLEAR ANNOTATION', {
      metaJobId: _self.job.id,
      assetId: id,
      cropType: cropType
    }, true);

    delete _self.assets[id].cropTypes[cropType].annotation;
  };

  _self.removeChangeData = function(id) {
    EventLoggerService.track('QA:CLEAR ALL_CHANGES', {
      metaJobId: _self.job.id,
      assetId: id
    }, true);

    delete _self.assets[id].approved;
    _self.removeAllAnnotations(id);
    _self.validateAllAssetsReviewed();
  };

  _self.removeAllAnnotations = function(id) {
    for (var index in _self.assets[id].cropTypes) {
      delete _self.assets[id].cropTypes[index].annotation;
    }
  };

  _self.allReviewSelected = function() {
    // If all assets are approved or at least 1 is rejected, the review complete button should be enabled
    let allApproved = true;
    for (var i in _self.assets) {
      if (_self.assets[i].approved === false) {
        return true;
      } else  if(_self.assets[i].approved === undefined) {
        allApproved = false;
      }
    }
    return allApproved;
  };

  _self.findAssetRejection = function() {
    // returns true if any asset is rejected.
    for (var i in _self.assets) {
      if (_self.assets[i].approved === false) {
        return true;
      }
    }
    return false;
  };

  _self.qaComplete = function(qaCompleteBody) {
    EventLoggerService.track('QA:COMPLETE', {
      metaJobId: _self.job.id
    }, true);

    return $http.post(`${REST_URL.base}/jobs/qacomplete`, qaCompleteBody);
  };

  _self.completeReview = function(params) {
    params = params || {};
    var deferred = $q.defer();

    var updateLibraryViewCodes = function() {
      var promises = [];
      if (_self.job.product.vendorCode.toUpperCase() !== 'CONVERSE') {
        //This is a flag we are sending to prevent the PUT when using complete to send a job to product Review
        if (params && !params.outreach) {
          EventLoggerService.track('QA:UPDATE LVCS', {
            metaJobId: _self.job.id
          }, true);

          angular.forEach(_self.assets, function(asset) {
            angular.forEach(_self.job.expectedCropTypes, function(
              expectedCropType
            ) {
              if((expectedCropType === "1x1" || expectedCropType === "4x5") && !!asset.cropTypes[expectedCropType]) {
                const predictedLVC = params.predictedLVCs ? params.predictedLVCs[asset.job.viewCode] : null;
                let url = `${REST_URL.base}/assets/${asset.cropTypes[expectedCropType].data.id}/libraryViewCode/${asset.job.libraryViewCode}`;
                if(predictedLVC) {
                  url += `?suggested=${predictedLVC}`;
                }
                promises.push(
                  $http.put(url,
                    {
                      jobId: asset.job.metaJobId,
                      viewCode: asset.job.viewCode
                    }
                  )
                );
              }
            });
          });
        }
      }

      // This is required in case there are 0 promises. This will push the chain along.
      if (promises.length == 0) {
        promises.push($q.when());
      }
      return $q.all(promises);
    };

    // upload annotations for rejected assets
    var uploadAnnotations = function() {
      var productId = _self.job.product.id;
      var promises = [];

      angular.forEach(_self.assets, function(assetJob) {
        var annotations = [];
        angular.forEach(_self.job.expectedCropTypes, function(
          expectedCropType
        ) {
          var asset = assetJob.cropTypes[expectedCropType];
          if (assetJob.job.status === ASSET_STATUS.review) {
            return;
          }
          if(!!asset) {
            var imageName = asset.data.fileName;
            if (asset.annotation) {
              if (asset.pastAnnotations) {
                asset.pastAnnotations.unshift(asset.annotation);
                annotations = asset.pastAnnotations;
              } else {
                annotations.unshift(asset.annotation);
              }

              EventLoggerService.track('QA:UPLOAD ANNOTATION', {
                metaJobId: _self.job.id,
                assetId: asset.data.id,
                cropType: expectedCropType,
                annotationCount: annotations.length
              }, true);

              angular.forEach(annotations, function(annotation) {
                _self.sendAnnotationToNewRelic('QA:DEBUG ANNOTATION', _self.job.id, asset.data.id, expectedCropType, annotation);
              });

              promises.push(
                $http.post(
                  [
                    REST_URL.base,
                    'products',
                    productId,
                    'assets',
                    imageName,
                    'annotations'
                  ].join('/'),
                  annotations
                )
              );
            }
          }
        });
      });

      return $q.all(promises);
    };

    // Complete Tasks (see http://apidoc.nike.ixxus.io/#!/default/post_jobs_complete)
    var completeTasks = function() {
      return _self.qaComplete(params.payload);
    };

    updateLibraryViewCodes()
      .then(function() {
        return uploadAnnotations();
      })
      .then(function() {
        if (params && !params.outreach) {
          return completeTasks();
        } else {
          return $q.when();
        }
      })
      .then(function(response) {
        deferred.resolve(response);
      }).catch(function (rej) {
        if (rej.data) {
          return deferred.reject(JSON.stringify(rej.data));
        } else {
          return deferred.reject(
            `There was an error uploading annotations. Please Try Again`
          );
        }
      });

    return deferred.promise;
  };

  _self.init = function(job) {
    _self.job = job;
    _self.assets = {};
    //check assetjob that's only in Image QA or In Product Review
    _self.job.assetJobs.forEach(function(assetJob) {
      // (AssetImage and AssetJob do not have a common ID, so we are comparing filenames)
      // Unedited assets cannot be rejected, so they aren't considered
      var imageAsset = _self.job.product.assets.find(function(current) {
        return (
          current.type === 'Deliverable' &&
          assetJob.assetFilenames.indexOf(current.fileName) > -1
        );
      });

      if (
        assetJob.status === ASSET_STATUS.qa ||
        assetJob.status === ASSET_STATUS.review
      ) {
        _self.assets[assetJob.id] = {
          job: assetJob,
          cropTypes: {},
        };

        _self.job.expectedCropTypes.forEach(function(cropType) {
          var asset = assetJob.cropTypes[cropType];
          if (asset) {
            _self.assets[assetJob.id].cropTypes[asset.cropType] = {
              data: asset,
              pastAnnotations: []
            };

            JobService.fetchAnnotation(asset).then(function(response) {
              _self.assets[assetJob.id].cropTypes[
                asset.cropType
              ].pastAnnotations = response.data;
            });
          }
        });
      }
    });
    _self.data.reviewStarted = false;
    _self.data.allAssetsReviewed = _self.allReviewSelected(); //disable review button unless valid state
    _self.data.hasRejection = _self.findAssetRejection();
  };
};

module.exports = QAService;
